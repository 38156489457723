<script>
// Import necessary modules and components
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";
import Constants from "@/data/Constants";
import Utils from "@/helpers/Utils";

// Create instances of HttpRequest and Preference classes
let httpRequest = new HttpRequest();
let preference = new Preference();

export default {
  // Initial data for the component
  data() {
    return {
      // Application name placeholder
      appName: "",

      // Component title
      title: "Reports",

      // Breadcrumb items
      items: [
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Report",
          active: true,
        },
      ],

      // Loading states for various data fetching processes
      isLoading: false,
      isLoadingMembers: false,

      // Lists for project and member selection
      projectListSelectOption: [],
      projectIdList: [],
      memberListSelectOption: [],
      selectedMemberId: null,

      // Date range for report
      fromDate: null,
      toDate: null,

      // 'All' and 'Me' options for project and member selection
      all: {
        id: null,
        name: "All",
      },
      allByDates: {
        id: null,
        name: "All by Dates",
      },
      allByMember: {
        id: "all-by-members",
        name: "All by Members",
      },
      me: {
        id: null,
        name: "Me",
      },

      // Selected project ID and ownership status
      selectedProjectId: null,
      isOwnerOfTheSelectedProject: false,

      // Table data and fields for report display
      reportTableData: [],
      reportTableFields: [
        { key: "date", sortable: false, label: "Date" },
        { key: "total_time", sortable: false, label: "Total Time" },
        { key: "idle_time", sortable: false, label: "Thinking Time" },
        { key: "active_time", sortable: false, label: "Executing Time" },
        { key: "percentage", sortable: false, label: "Percentage" },
      ],
    };
  },

  // Registered components for the current Vue instance
  components: {
    Layout,
    PageHeader,
  },

  methods: {
    // Function to fetch the list of projects
    getProjectList() {
      // Save reference to the current instance of the component
      let _this = this;

      // Set isLoading flag to true before making the request
      _this.isLoading = true;

      // Make an HTTP request to get the list of projects
      return httpRequest.getProjectList().then(function (response) {
        // Set isLoading flag to false after receiving the response
        _this.isLoading = false;

        // Check if the request was successful
        if (response.success) {
          // Iterate through the data and process project information
          response.data.forEach(function (projectData) {
            if (projectData.project.project_status == 1) {
              // Create a project object with id and name
              let project = {
                id: projectData.project.project_id,
                name: projectData.project.project_name,
              };

              // Add the project to projectListSelectOption
              _this.projectListSelectOption.push(project);

              // Check if the user has the role of a product owner for the project
              if (projectData.role_id === Constants.ROLE_PRODUCT_OWNER) {
                // Add the project ID to projectIdList
                _this.projectIdList.push(projectData.project.project_id);
              }
            }
          });
        }
      });
    },

    // Function to fetch the list of project members
    getProjectMemberList() {
      // Save reference to the current instance of the component
      let _this = this;

      // Initialize memberListSelectOption
      _this.memberListSelectOption = [];

      // Check if a project is selected
      if (_this.selectedProjectId != null) {
        // Set isLoadingMembers flag to true before making the request
        _this.isLoadingMembers = true;

        // Make an HTTP request to get project members based on the selected project
        httpRequest.getProjectMembers(_this.selectedProjectId).then(function (response) {
          // Set isLoadingMembers flag to false after receiving the response
          _this.isLoadingMembers = false;

          // Check if the request was successful
          if (response.success) {
            // Get the user ID of the current user from preferences
            let myUserId = preference.getData(Preference.KEY_USER_ID);

            // Initialize flag for ownership of the selected project
            _this.isOwnerOfTheSelectedProject = false;

            // Iterate through project members and process information
            response.data.forEach(function (member) {
              // Initialize variables for user ID and role ID
              let myRoleId = -1;

              // Check if the member has user information
              if (member.user !== null) {
                // Check if the current user is part of the project
                if (member.user.user_id === myUserId) {
                  // Set the selectedMemberId to the current user's ID
                  _this.selectedMemberId = member.user.user_id;
                  myRoleId = member.role_id;

                  // Check if the current user has the role of a product owner
                  _this.isOwnerOfTheSelectedProject =
                    myRoleId === Constants.ROLE_PRODUCT_OWNER;
                }

                // Create a user object with ID and name
                let user = {
                  id: member.user.user_id,
                  name: member.user.name,
                };

                // Add the user to memberListSelectOption
                _this.memberListSelectOption.push(user);
              }
            });

            // Add special options to memberListSelectOption
            _this.memberListSelectOption.push(_this.allByDates);
            _this.memberListSelectOption.push(_this.allByMember);

            // Check if the current user is not the owner of the selected project
            if (!_this.isOwnerOfTheSelectedProject) {
              // Reset memberListSelectOption and add the current user as the only option
              _this.memberListSelectOption = [];
              let user = {
                id: _this.selectedMemberId,
                name: preference.getData(Preference.KEY_NAME),
              };
              _this.memberListSelectOption.push(user);
            }
          }
        });
      } else {
        // If no project is selected, fetch all members based on the project list
        _this.getAllMembersBasedOnProjectList();
      }
    },

    // Function to fetch all members based on the project list
    getAllMembersBasedOnProjectList() {
      // Save reference to the current instance of the component
      let _this = this;

      // Initialize memberListSelectOption
      _this.memberListSelectOption = [];

      // Check if there are projects in the projectIdList
      if (_this.projectIdList.length > 0) {
        // Set isLoadingMembers flag to true before making the request
        _this.isLoadingMembers = true;

        // Make an HTTP request to get project members based on the project ID list
        httpRequest.getProjectMembers(_this.projectIdList).then(function (response) {
          // Set isLoadingMembers flag to false after receiving the response
          _this.isLoadingMembers = false;

          // Check if the request was successful
          if (response.success) {
            // Initialize flag for ownership of the selected project
            _this.isOwnerOfTheSelectedProject = false;

            // Iterate through project members and process information
            response.data.forEach(function (member) {
              // Check if the member has user information
              if (member.user !== null) {
                // Create a user object with ID and name
                let user = {
                  id: member.user.user_id,
                  name: member.user.name,
                };

                // Add the user to memberListSelectOption
                _this.memberListSelectOption.push(user);
              }
            });

            // Remove duplicates from memberListSelectOption based on user ID
            if (_this.memberListSelectOption.length > 0) {
              const key = "id";
              _this.memberListSelectOption = [
                ...new Map(
                  _this.memberListSelectOption.map((item) => [item[key], item])
                ).values(),
              ];
            } else {
              // If no members are found, add the current user as an option
              _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
              _this.me.id = _this.selectedMemberId;
              _this.me.name = preference.getData(Preference.KEY_NAME);
              _this.memberListSelectOption.push(_this.me);
            }
          }
        });
      } else {
        // If no projects are in the projectIdList, add the current user as an option
        _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);
        _this.me.id = _this.selectedMemberId;
        _this.me.name = preference.getData(Preference.KEY_NAME);
        _this.memberListSelectOption.push(_this.me);
      }
    },

    // Function to load selected data for report preparation
    loadSelectedData() {
      // Save reference to the current instance of the component
      let _this = this;

      // Format the fromDate and toDate using Utils.formatDate
      let fromDate = Utils.formatDate(_this.fromDate);
      let toDate = Utils.formatDate(_this.toDate);

      // Set isLoading flag to true before making the request
      _this.isLoading = true;

      // Get the user's timezone using Utils.getMyTimezone
      let timezone = Utils.getMyTimezone();

      // Check if the selectedMemberId is "all-by-members" for all project members
      if (_this.selectedMemberId == "all-by-members") {
        // Set reportTableFields for all project members
        _this.reportTableFields = [
          { key: "user_name", sortable: false, label: "Name" },
          { key: "email", sortable: false, label: "Email" },
          // {key: "date", sortable: false, label: "Date"},
          { key: "total_time", sortable: false, label: "Total Time" },
          { key: "idle_time", sortable: false, label: "Thinking Time" },
          { key: "active_time", sortable: false, label: "Executing Time" },
          { key: "percentage", sortable: false, label: "Percentage" },
        ];
      } else {
        // Set reportTableFields for a single project member
        _this.reportTableFields = [
          { key: "date", sortable: false, label: "Date" },
          { key: "total_time", sortable: false, label: "Total Time" },
          { key: "idle_time", sortable: false, label: "Thinking Time" },
          { key: "active_time", sortable: false, label: "Executing Time" },
          { key: "percentage", sortable: false, label: "Percentage" },
        ];
      }

      // Make an HTTP request to get report preparation data
      httpRequest
        .getReportPreparationData(
          fromDate,
          toDate,
          _this.selectedProjectId,
          _this.selectedMemberId,
          timezone
        )
        .then(function (response) {
          // Set isLoading flag to false after receiving the response
          _this.isLoading = false;

          // Initialize reportTableData
          _this.reportTableData = [];

          // Check if the request was successful
          if (response.success) {
            // Check if there is data in the response
            if (response.data.length > 0) {
              let reportData;
              let total_sum_time = 0;
              let total_idle_time = 0;
              let active_time = 0;

              // Iterate through the response data and process report information
              response.data.forEach(function (data) {
                total_sum_time = total_sum_time + parseInt(data.total_time);
                total_idle_time = total_idle_time + parseInt(data.idle_time);
                active_time = active_time + parseInt(data.active_time);

                reportData = {
                  user_name: data.name,
                  email: data.email,
                  date: data.date,
                  total_time: Utils.formatTime(data.total_time),
                  idle_time: Utils.formatTime(data.idle_time),
                  active_time: Utils.formatTime(data.total_time - data.idle_time),
                  percentage:
                    Math.round(
                      ((data.total_time - data.idle_time) * 100) / data.total_time
                    ) + "%",
                };

                // Add reportData to reportTableData
                _this.reportTableData.push(reportData);
              });

              // Add total summary data to reportTableData
              reportData = {
                date: "Total  ",
                email: _this.selectedMemberId == "all-by-members" ? "Total " : "",
                total_time: Utils.formatTime(total_sum_time.toString()),
                idle_time: Utils.formatTime(total_idle_time.toString()),
                active_time: Utils.formatTime(total_sum_time - total_idle_time),
                percentage:
                  Math.round(
                    ((total_sum_time - total_idle_time) * 100) / total_sum_time
                  ) + "%",
              };
              _this.reportTableData.push(reportData);
            }
          }
        });
    },

    // Function to load initial data for the component
    loadInitialData() {
      // Set fromDate and toDate to today's date
      this.fromDate = Utils.getToday().replaceAll("/", "-");
      this.toDate = Utils.getToday().replaceAll("/", "-");

      // Add the "All" option to projectListSelectOption
      this.projectListSelectOption.push(this.all);

      // Check if selectedMemberId is not set
      if (this.selectedMemberId == null) {
        // Set selectedMemberId to the user's ID from preferences
        this.selectedMemberId = preference.getData(Preference.KEY_USER_ID);

        // Set the user information for the "Me" option
        this.me.id = this.selectedMemberId;
        this.me.name = preference.getData(Preference.KEY_NAME);
      }

      // Add the "Me" option to memberListSelectOption
      this.memberListSelectOption.push(this.me);

      // Load data for the selected member
      this.loadSelectedData();
    },

    // Function to export selected data as a CSV file
    exportSelectedData() {
      // Save reference to the current instance of the component
      let _this = this;

      // Initialize variables for file name and user name
      let fileName = "";
      let user_name = "";

      // Iterate through memberListSelectOption to find the selected member's information
      this.memberListSelectOption.forEach(function (item) {
        if (item.id === _this.selectedMemberId) {
          // Get the current date and time
          let today = new Date();
          let time =
            today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();

          // Construct the file name using the member's name, date range, and time
          fileName =
            item.name + "_" + _this.fromDate + "_to_" + _this.toDate + "_" + time;

          // Save the user name for later use
          user_name = item.name;
        }
      });

      // Check if there is data in the reportTableData
      if (this.reportTableData.length > 0) {
        // Convert the report data to CSV format and initiate the download
        Utils.convertToCsv(this.reportTableData, fileName, user_name);
      }
    },

    // Function to expand URLs in the provided data
    expandUrls(data) {
      // Set the 'expand' property of the data to true
      data.expand = true;
    },

    // Function to get the hostname from a given URL string
    getHostnameFromUrl(string) {
      // Check if the string is not empty
      if (string) {
        try {
          // Try to create a new URL object and return the hostname
          return new URL(string).hostname;
        } catch (error) {
          // If an error occurs (invalid URL), return the original string
          return string;
        }
      }
      // If the string is empty, return null
      return null;
    },

    // Function to print data if there is data in the reportTableData
    printData() {
      if (this.reportTableData.length > 0) {
        // Trigger the browser's print functionality
        window.print();
      }
    },
  },
  async created() {
    await this.loadInitialData();
    await this.getProjectList();
    await this.getAllMembersBasedOnProjectList();
    this.appName = this.$route.query.app_name || "DefaultAppName";
    
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row report-form-row">
              <div class="col-sm-6 col-md-6 col-xl-3">
                <label class="report-form-label">From</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <!-- Icon or content to prepend -->
                        <span class="input-group-text">
                          <i class="ri-calendar-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-datepicker
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                        id="from-datepicker"
                        v-model="fromDate"
                        placeholder="Today"
                      ></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-6 col-xl-3">
                <label class="report-form-label">To</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-calendar-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-datepicker
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                        id="to-datepicker"
                        v-model="toDate"
                        placeholder="Today"
                      ></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-2">
                <label class="report-form-label">Project</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-folder-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-select
                        :options="projectListSelectOption"
                        v-model="selectedProjectId"
                        value-field="id"
                        text-field="name"
                        v-on:change="getProjectMemberList"
                      ></b-form-select>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-2">
                <label class="report-form-label">Member</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                          <i class="ri-user-3-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-select
                        :options="memberListSelectOption"
                        v-model="selectedMemberId"
                        value-field="id"
                        text-field="name"
                        :disabled="isLoadingMembers"
                      ></b-form-select>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-md-4 col-xl-2 report-search">
                <label class="report-form-label">Action</label>
                <b-button
                  block
                  variant="primary"
                  class="customs-bg-color"
                  v-on:click="loadSelectedData"
                  ><i class="ri-eye-line" style="vertical-align: bottom"></i> VIEW
                </b-button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <b-table
                  ref="projectTable"
                  show-empty
                  :items="reportTableData"
                  :striped="true"
                  responsive=""
                  :fields="reportTableFields"
                  :bordered="true"
                  :hover="true"
                  :dark="false"
                  :busy="isLoading"
                  id="reportTable"
                  caption-top
                >
                  <template #table-caption v-if="reportTableData.length > 0">
                    <div
                      class="alert alert-info text-center"
                      style="
                        background: #fff;
                        border: none;
                        margin-top: -10px;
                        position: relative;
                        padding: 0;
                      "
                      role="alert"
                      v-if="!isLoading"
                    >
                      <div
                        class="alert alert-info- mt-3- pb-1 d-flex justify-content-between align-items-center"
                        style="border: 1px solid #ced4da; padding: 5px 18px"
                      >
                        <span
                          class=""
                          :style="{
                            color: 'black',
                            fontSize: '15px',
                            fontWeight: '700',
                            marginLeft: '0px',
                          }"
                        >
                          {{
                            memberListSelectOption.find(
                              (member) => member.id === selectedMemberId
                            )?.name
                          }}
                          -
                          {{
                            projectListSelectOption.find(
                              (project) => project.id === selectedProjectId
                            )?.name
                          }}
                          - {{ fromDate }} to {{ toDate }}
                        </span>
                        <div class="actions">
                          <i
                            class="ri-file-download-line text-primary- cursor-pointer"
                            style="
                              vertical-align: bottom;
                              font-size: 22px;
                              margin-right: 10px;
                              cursor: pointer;
                              color: #ff7c47;
                            "
                            v-on:click="exportSelectedData"
                            v-b-tooltip.hover.top
                            title="Download CSV"
                          >
                          </i>
                          <i
                            class="ri-printer-line text-primary- cursor-pointer"
                            style="
                              vertical-align: bottom;
                              font-size: 22px;
                              cursor: pointer;
                              color: #ff7c47;
                            "
                            v-on:click="printData"
                            v-b-tooltip.hover.top
                            title="Print"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template
                    v-for="field in reportTableFields"
                    v-slot:[`head(${field.key})`]="{ label }"
                  >
                    <span class="customs-text-color" v-bind:key="field.key">{{
                      label
                    }}</span>
                  </template>

                  <template #cell(url_data)="row">
                    <div v-if="row.item.expand">
                      <ul>
                        <li v-for="(item, index) in row.item.url_data" :key="index">
                          <a target="_blank" :href="item.url"> {{ item.siteName }} </a>
                        </li>
                      </ul>
                    </div>
                    <div v-if="!row.item.expand && row.item.url_data.length > 0">
                      <b-button variant="link" v-on:click="expandUrls(row.item)"
                        >Visited {{ row.item.url_data.length }} URL
                      </b-button>
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="report-bottom text-right" v-if="reportTableData.length > 0"></div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
@media print {
  .card {
    font-size: 1rem;
  }

  .report-form-label,
  .actions {
    display: none;
    padding-top: 15px;
    margin-bottom: -10px;
  }

  .report-form-row > div {
    display: none;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .report-form-row .form-control,
  .report-form-row .form-control label,
  .report-form-row .custom-select {
    display: none !important;
  }

  .b-form-btn-label-control.form-control > #from-datepicker,
  .b-form-btn-label-control.form-control > #to-datepicker,
  .report-search,
  .report-bottom {
    display: none !important;
  }
}

.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

#from-datepicker > svg {
  display: none;
}
#to-datepicker > svg {
  display: none;
}

#reportTable tr:last-child td {
  color: black;
  font-weight: bolder;
}
</style>
